<script setup lang="ts">
import { ref } from 'vue';
import { RouterLink } from 'vue-router';
const primary = ref('rgb(var(--v-theme-primary))');
const secondary = ref('rgb(var(--v-theme-secondary))');
import LogoLight from '/public/images/logos/snapei-logo-white.svg';
</script>
<template>
    <div class="logo">
        <NuxtLink to="/">
            <VImg :src="LogoLight" height="80" width="340" >
            </VImg>
        </NuxtLink>
    </div>
</template>
